<template>
    <div id="patient-list-section" class="create-section mt-sm-5 mt-3">
        <div class="container">
            <form @submit.prevent="getList()">
                <div class="mb-3 row">
                    <div class="col-sm-2 mb-2 mb-sm-0">
                        <create-action v-if="$root.$can('patient', 'patient-create')" name="Patient"
                                       to_name="patientCreate"/>
                    </div>
                    <div class="col-sm-3 mb-2 mb-sm-0 ms-auto">
                        <date-picker-component class="w-100" title="Reg. Date" v_model="register_date"/>
                    </div>
                    <div class="col-sm-3">
                        <input v-model="table.search" type="text" class="form-control mb-3" id="name"
                               placeholder="Searching...">
                    </div>
                    <div class="col-sm-2">
                        <input type="submit" class="btn btn-primary w-100" value="Searching">
                    </div>
                </div>
            </form>
            <div class="row">
                <div class="table-responsive-sm">
                    <table class="table table-striped table-hover">
                        <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">ID</th>
                            <th scope="col">Full Name</th>
                            <th scope="col">Contact</th>
                            <th scope="col">Gender</th>
                            <th scope="col">Age</th>
                            <!--                        <th scope="col">Assign Doctor</th>-->
                            <th scope="col">CreatedBy</th>
                            <th scope="col">Reg. Date</th>
                            <th scope="col">Image</th>
                            <th scope="col">Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-if="lists.length">
                            <tr v-for="(list, index) in lists" class="align-middle">
                                <th scope="row">{{ table.pagination.from + index }}</th>
                                <td>{{ list.id_number }}</td>
                                <td>{{ list.name }}</td>
                                <td>P: {{ list.phone }}<br>E: {{ list.email }}</td>
                                <td>{{ list.gender }}</td>
                                <td>M: {{ list.date_of_month }} Y: {{ list.date_of_year }}</td>
                                <!--                            <td>{{ list.assigned_doctor ? list.assigned_doctor.name : '' }}</td>-->
                                <td>{{ list.created_by ? list.created_by.name : '' }}</td>
                                <td>{{ list.register_date | dateFormat }}</td>
                                <td>
                                    <div class="form-image-preview">
                                        <img class="form-img img-thumbnail w-100 h-100" :src="list.image"/>
                                    </div>
                                </td>
                                <td>
                                    <div class="dropdown">
                                        <button class="btn btn-primary dropdown-toggle" type="button"
                                                id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            Action
                                        </button>
                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton1"
                                             style="padding: 10px 15px!important;">
                                            <div class="d-flex justify-content-around align-items-center">
                                                <update-action v-if="$root.$can('patient', 'patient-update')"
                                                               to_name="patientEdit"
                                                               :params="{ id: list.id }"/>
                                                <router-link v-if="$root.$can('visit', 'visit-create')" title="New Visit"
                                                             :to="{name: 'prescriptionCreate', query: {patient_id: list.id}}"
                                                             class="btn btn-primary m-1">
                                                    <i class="far fa-calendar-plus"></i>
                                                </router-link>
                                                <router-link v-if="$root.$can('visit', 'visit-view')" title="All Visit"
                                                             :to="{name: 'prescriptionList', query: {patient_id: list.id}}"
                                                             class="btn btn-primary m-1">
                                                    <i class="far fa-calendar-alt"></i>
                                                </router-link>
                                                <router-link v-if="$root.$can('bill', 'bill-create')" title="Create Bill"
                                                             :to="{name: 'invoiceCreate', query: {patient_id: list.id}}"
                                                             class="btn btn-primary m-1">
                                                    <i class="fas fa-calculator"></i>
                                                </router-link>
                                                <router-link v-if="$root.$can('bill', 'bill-view')" title="All Bill"
                                                             :to="{name: 'invoiceList', query: {patient_id: list.id}}"
                                                             class="btn btn-primary m-1">
                                                    <i class="fab fa-buffer"></i>
                                                </router-link>
                                                <delete-action v-if="$root.$can('patient', 'patient-delete')" class="w-auto"
                                                               :id="list.id"/>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </template>
                        <template v-if="!lists.length">
                            <tr>
                                <th class="text-center font-weight-bolder" colspan="100%">No data found</th>
                            </tr>
                        </template>
                        </tbody>
                    </table>
                </div>
                <pagination v-if="lists.length>0" :pagination="table.pagination" @paginate="getList()" :offset="5"/>
            </div>
        </div>
    </div>
</template>

<script>
import ApiService          from "@/services/api.service";
import NotificationService from "@/services/notification.service";
import Pagination          from "@/components/Pagination";
import DatePickerComponent from "@/components/picker/DatePicker";
import moment              from "moment";

export default {
    name      : "PatientList",
    components: {Pagination, DatePickerComponent},
    data      : () => ({
        lists: [],
        form : {
            register_date: '',
        },
        table: {
            per_page     : 10,
            search       : '',
            register_date: '',
            pagination   : {
                current_page: 1
            }
        }
    }),
    mounted() {
        this.getList();
    },
    methods: {
        //-------CRUD------
        getList() {
            let params = {
                ...this.table,
                page         : this.table.pagination.current_page,
                register_date: this.form.register_date,
            };
            ApiService.get('/patients', {params: params}).then((res) => {
                this.lists            = res.data.data;
                this.table.pagination = res.data.meta;
            }).catch(error => {
                NotificationService.error(error.response.data.message);
            })
        },
        destroy(id) {
            Swal.fire({
                title             : 'Are you sure?',
                text              : "You won't be able to revert this!",
                icon              : 'warning',
                showCancelButton  : true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor : '#d33',
                confirmButtonText : 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    ApiService.delete(`/patients/${id}`,).then(res => {
                        this.getList();
                        NotificationService.success(res.data.message);
                    }).catch(error => {
                        NotificationService.error(error.response.data.message);
                    })
                }
            }).catch(error => {
                NotificationService.error(error.response.data.message);
            })
        },
        //-------CRUD--------
    }
}
</script>
